import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const runWindow = () => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(
      messageEvent,
      function (e) {
        if (typeof e.data === "string") {
          navigate(e.data);
        }
      },
      false
    );
  };

  return (
    <iframe
      onLoad={runWindow}
      id="iframePage"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        border: "none",
        margin: 0,
        padding: 0,
        overflow: "hidden",
        zIndex: "999999",
      }}
      src={`${process.env.REACT_APP_HUBSPOT_BASE}/`}
    />
  );
}

export default Home;
